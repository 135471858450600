.client-title {
  font-family: "Montserrat";
  font-size: 1.2em;
  text-transform: uppercase;
}

.client input,
.client select {
  border-radius: 0;
  font-family: "Montserrat";
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 10px 15px;
}

.client .select-country {
  font-family: "Montserrat";
  font-size: 1em;
}

.client .select-country div[class*="ValueContainer"] {
  padding-left: 15px;
}

.txt-monserrat {
  font-family: "Montserrat";
}

.client .select {
  font-family: "Montserrat";
}

.client .select div[class*="ValueContainer"] {
  padding-left: 15px;
}

.buy-container-client a {
  color: #222!important;
  font-family: "Futura";
  font-size: 0.8em;
  cursor: pointer;
  text-decoration: underline!important;
  margin-bottom: 1.2em;
}

.buy-container-client a:hover {
  color: #999!important;
  cursor: pointer;
}

.cgv-button {
  text-decoration: underline;
  cursor: pointer;
}

.cgv-button:hover {
  color: #999;
}


@media (max-width: 768px) {
  #shipping-container {
    width: 100%;
    padding:0;
    margin: 0;
  }

}