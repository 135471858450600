.footer {
  background-color: var(--background-2);
  font-family: "Montserrat";
  padding-top: 15px !important;
  border-top: 1px solid #ccc;
}

.footer .links {
  font-size: 0.7em;
  text-align: center;
  padding: 10px 5px;
  
}

.links a, .links p {
  color: black;
  cursor: pointer;
  text-decoration: none!important;
  
}

.links a:hover, .links p:hover {
  color: #999!important;
}

.footer .end {
  padding: 10px;
  font-size: 0.6em;
  width: 100%;
  background-color: #eaeaea;
}
