@font-face {
  font-family: "Agrandir Grand";
  src: url("../fonts/agrandir-grand.ttf");
  
}

@font-face {
  font-family: "Agrandir Grand Bold";
  src: url("../fonts/agrandir-grand-bold.ttf");
  
}

@font-face {
  font-family: "Ashing";
  src: url("../fonts/Ahsing-Regular.otf");
  
}

@font-face {
  font-family: "Souvenir-Medium";
  src: url("../fonts/Souvenir-Medium.otf");
  
}

@font-face {
  font-family: "Souvenir-Bold";
  src: url("../fonts/Souvenir-Bold.otf");
  
}

@font-face {
  font-family: "Souvenir-Demi";
  src: url("../fonts/Souvenir-Demi.otf");
  
}

@font-face {
  font-family: "Souvenir-Light";
  src: url("../fonts/Souvenir-Light.otf");
  
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-VariableFont_wght.ttf");
  
}

/* @font-face {
  font-family: "Futura";
  src: url("../fonts/FuturaLT.ttf");
} */



:root {
  --main-color: #760e1b;
  --background: #f3f4f0;
  --background-2: #f0f0f0;
  --white: #fff;
  --black: #121111;
  --price: #b33339;
}

.txt-futura {
  font-family: "Futura";
}
