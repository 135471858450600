/* PRODUCT */
.product-container {
  min-height: 550px;
  padding-bottom: 50px !important;
}

.product-container .swip-container {
  max-height: 100%;
}

.swip-container.mobile-hidden {
  padding-left: 5em;
  position: relative;
}

.product-container .swip-container .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-container .swiper-img {
  position: relative;
  height: 70%;
}

.product-container .product-info {
  text-transform: uppercase;
}

.product-container .product-info .title {
  font-family: "Agrandir Grand Bold";
  font-weight: bold;
  font-size: 1.1em;
  letter-spacing: 9px;
}

.product-container .product-info .sub-title {
  font-family: "Futura";
  font-size: 0.9em;
  letter-spacing: 4px;
}

.product-container .product-info .price {
  color: var(--price);
  font-family: "Futura";
  font-size: 0.7em;
  font-weight: bold;
  letter-spacing: 1.4px;
}

.product-container .price.oney {
  font-family: "Futura";
  font-size: 0.7em;
}

/*  PAIEMENT ONEY */
.oney-text {
  color: #8bbd06;
  font-size: 0.8em;
  font-family: "Futura";
}

.oney img {
  margin-right: 10px;
  margin-top: 5px;
}

.oney button {
  border: none;
  font-weight: bold;
  padding: 5px 10px;
  color: #8bbd06;
}
.oney button:hover,
.oney button.selected {
  background-color: #8bbd06;
  color: white;
}

.oney .price {
  font-size: 1.5em;
}

.product-btn-buy {
  text-decoration: none;
  font-size: 1em;
  background-color: var(--main-color) !important;
  color: white !important;
  text-transform: uppercase;
  box-shadow: none;
  border-color: var(--main-color) !important;
  border-radius: 0;
  padding: 0.5em 1em;
  font-family: "Montserrat";
  font-weight: bold;
}

/*  TAB */
.product-info-tabs .tab {
  font-family: "Montserrat";
  border-bottom: 1px solid black;
  font-size: 0.9em;
  min-height: 3em;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-info-tabs .tab .child {
  font-family: 'Futura';
  text-transform: none;
  text-align: justify;
}

.product-info-tabs .tab.bd-top {
  border-top: 1px solid black;
}

.product-info-tabs .tab:hover {
  background: #eee;
}

.product-info-tabs .tab .child {
  height: 0;
  visibility: hidden;
  transition: all 10ms ease-in-out;
  font-size: 1em;
}

.product-info-tabs .tab .child.open {
  height: auto;
  visibility: visible;
  padding: 10px 0;
}

.tab .tab-title {
  text-align: center;
}

.tab .tab-title.open {
  font-weight: bold;
}

.swiper-button-prev,
.swiper-button-next {
  color: black;
}

.slide-img {
  height: 800px;
  max-width: 100%;
}

.swiper-pagination-bullet {
  background-color: white !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white !important;
}

.material .material-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  /* box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px; */
}

.material .material-item.selected {
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  /* box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  border: 1.5px solid #555;
}

/* .material-item.gold18k {
  background-image: url("../../img/properties/or18k.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.material-item.gold9k {
  background-image: url("../../img/properties/or9k.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.material-item.silver925 {
  background-image: url("../../img/properties/argent925.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

.material-item.fakeGold {
  background-image: url("../../img/properties/plaqueor.jpg");
  background-position: center;
  background-repeat: no-repeat;
} */

.material p {
  font-size: 0.8em;
  font-family: "Futura";
  text-transform: none;
}

.select-material, .select-size  {
  font-family: "Futura";
  font-size: 0.85em;
}


.size-guide {
  background-color:transparent;
  font-family: 'Futura';
  border:none;
  color: black;
  font-size: 0.8em;
  font-family: "Futura";
  cursor: pointer;
  padding: 5px;
  text-transform: none;
}

.size-guide:hover, .size-guide:focus {
  color: #999;
  background-color: transparent;
}

.modal-size-guide {
  top: 60px;
}

.modal-size-guide .close-icon {
  font-size: 0.8em;
}

.select-size {
  min-width: 80px;
  max-width: 115px;
}

.marge-product {
  margin-top: 2em;
}

#swip-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 94%;
  z-index: 1000;
}

#swip-navigation button {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 2em;
}

@media (max-width: 1530px) {
  .slide-img {
    height: 730px;
  }

  .product-info {
    margin-left: 3em;
  }
}

@media (min-width: 768px) {
  .web-hidden {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .product-info {
    margin-left: 0em;
  }
  .slide-img {
    height: 450px;
  }

  .product-container {
    height: auto;
  }

  .product-container .product-info {
    margin-top: -120px;
  }

  .product-container .product-info .title {
    font-size: 1.3em;
    font-weight: bold;
  }

  .product-container .product-info .sub-title {
    font-size: 1.1em;
  }

  .product-container .product-info .price {
    font-size: 1em;
  }
  .product-info-tabs .tab .child {
    margin-top: 5px;
    font-size: 1em;
  }

  .swip-container {
    min-height: 600px;
    position: relative!important;
  }

  .swip-container.web-hidden {
    margin: auto;
  }

  .mobile-hidden {
    display: none !important;
  }

  .select-size {
    width: 100%;
  }

  .product-info-tabs .tab {
    font-size: 0.9em;
  }
}

@media (max-width: 380px) {
  .swip-container {
    min-height: 550px;
  }

  .slide-img {
    height: 400px;
  }

  .product-info-tabs .tab {
    font-size: 0.8em;
  }
}
