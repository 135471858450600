/*  BANDEAU  */
.bandeau {
  background-color: white;
  color: var(--main-color);
  font-family: "Futura";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.6em;
  text-align: center;
  color: black;
  position: relative;
}

.bandeau .branding {
  margin-top:10px;
  margin-bottom: 8px;
}

.bandeau .branding .title {
  font-size: 2.5em;
}

.bandeau .branding p {
  margin: 0;
  margin-top: -0.3em;
  font-size: 0.55m;
}

.bandeau .col {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* PARTIE DU LION */

.lion-head {
  height: 97%;
  position: relative;
}

.lion-head .branding {
  position: absolute;
  top: 1em;
}

.lion-head .branding .title {
  font-size: 2.5em;
}

.lion-head .branding p {
  font-size: 0.5em;
}

.lion-head .text-floating {
  position: absolute;
  bottom: -20px;
  right: 150px;
  color: var(--main-color);
  font-family: "Agrandir grand Bold";
  font-size: 0.8em;
}

.lion-head .text-floating p {
  margin: 0;
}

.lion-head .text {
  text-transform: uppercase;
}

.lion-head .text:nth-child(2) {
  text-align: right;
}

.language {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  visibility: hidden;
}

.presentation-type {
  bottom: -20px;
}

.languages {
  position: absolute;
  right: 0;
  top: 0;
  width: 95px;
}

.languages button {
  background: transparent;
  font-size: 1.3em;
  font-family: "Futura";
  color: black;
  border: none;
  border-radius: 0;
}

.languages button:hover {
  background-color: black;
  color: white;
}

.languages button.fr {
  padding-right: 10px;
  border-right: 1px solid #ccc;
  border-radius: 0;
}

@media (min-width: 900) and (max-width: 1530) {
    .lion-head .text-floating {
      width: 33%!important;
    }
}

@media (min-width: 1600) {
  .col-xl-3 {
    width: 25%;
  }
}

@media (max-width: 1200px) {
  .bandeau {
    font-size: 0.7em;
  }
  .lion-head .text-floating {
    font-size: 0.72em;
    font-size: 0.65em;
  }
}

@media (max-width: 768px) {
  .lion-head .branding {
    position: absolute;
    top: 0em;
  }

  .lion-head .text-floating {
    font-size: 0.65em;
    right: 10px;
  }
  .bandeau {
    font-size: 0.6em;
  }

  .lion-head {
    height: 90%;
    position: relative;
  }
}
