@import "./assets.css";

html,
body,
#root {
  height: 100%;
  background-color: var(--background);
  overflow-x: hidden;
}

@import "./parts/header_lion.css";
@import "./parts/branding.css";
@import "./parts/product.css";
@import "./parts/client.css";
@import "./parts/footer.css";


p {
  color: black;
}

.bandeau {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 11000;
}

.lion-head  {
  background-image: url('../img/lion-mobile.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--background-2);
}

.branding-mobile {
  display: none;
}

.lion-head .languages {
  display:none;   
}

@media (max-width: 500px) {
  .lion-head {
    background-image: url('../img/lion-mobile.png');
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
  }

  .mobile-hide {
    display:none!important;
  }

  .branding-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;

  }

  /* .bandeau .languages {
    display:none!important;
  } */
  .lion-head .languages {
    display: flex;
    position: absolute;
    right: 10px;
    top: 0px
  }
}

/* MODAL SUCCESS */

#modal-success-container {
  position: fixed;
  z-index: 1500;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-content-success {
  width: 80%;
  min-height: 400px;
  height: 400px;
  background-color: white;
  font-family: 'Futura';
}

#modal-content-success .check {
  color: green;
  font-size: 3.5em;
}