/* BRANDING  */

.branding-container {
  margin-top: 3em;
  margin-bottom: 4em;
  background-color: var(--background-2);
}

.branding {
  margin-bottom: 1.5em;
  margin-top: 2em;
}

.branding-container .branding {
  margin-bottom: 0em;
}

.branding .title {
  font-family: "Ashing";
  text-align: center;
  text-transform: uppercase;
  font-size: 3.5em;
  color: var(--main-color);
  margin: 0;
  letter-spacing: 0.1rem;
}

.branding .sub-title {
  font-size: 0.6em;
  font-family: "Agrandir grand";
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  margin-top: -0.6em;
}

.branding-container .description  {
  max-width: 1400px;
}
.branding-container .description p {
  width: 70%;
}

.branding-container .description,
.branding-container .footer {
  font-family: "Futura";
  text-align: justify;
  padding: 1rem 7em;
  text-transform: uppercase;
  font-size: 0.8em;
  color: var(--main-color);
  line-height: 25px;
  padding-bottom: 0;
}

.branding-container .footer {
  text-align: center;
  padding-top: 1em !important;
  border: none;
}

.branding-container .footer p {
  margin-bottom: 0!important;
}

.branding-container .social {
  gap: 10px;
}


.social-container {
  margin-bottom: 1.5em;
}

.brand-instagram {
  color: black;
}

.brand-instagram:hover {
  color: #888;
}

@media (max-width: 768px) {
  .branding-container .description p {
    width: 100%;
  }
  .branding-container .description,
  .branding-container .footer {
    padding: 1rem 1em;
    font-size: 0.72em!important;
  }

  .branding {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
  }
}
